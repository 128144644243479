import { useState } from 'react';
import logo from '../../../images/logo.png';
import { useNavigate } from 'react-router-dom';


export default function NavbarReparto() {
    const navigate = useNavigate();

    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const logout = async () => {
        try {
            localStorage.removeItem('auth_usuario');
            localStorage.removeItem('Codcomisionista');
            localStorage.removeItem('NombreRepartidor');
            navigate('/loginReparto');
        } catch (error) {
            console.log(error.response?.data?.message);
        }
    };


    return (
        // <nav className="bg-gradient-to-r from-black to-gray-800 p-4 lg:p-1 fixed w-full ">
        <nav className="bg-white p-4 lg:p-1 fixed w-full shadow shadow-black">
            <div className="container mx-auto flex justify-between items-center ">
                {/* Logo */}
                <a href="/layoutReparto" className="flex items-center">
                    <img src={logo} alt="logo" className="lg:w-20 lg:h-10 w-20 h-10" />
                </a>

                {/* Mobile Menu Toggle */}
                <button
                    type="button"
                    className="text-white md:hidden focus:outline-none"
                    onClick={toggleMenu}
                >
                    <svg
                        className="h-9 w-9"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="black"
                        strokeWidth="3"
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" d={`${isOpen == true ? 'M4 6h16M4 12h16m-7 6h7' : 'M4 6h16M4 12h16M4 18h16'} `} />
                    </svg>
                </button>


                {/* Navigation Links */}
                <div
                    className={`absolute  top-full left-0 right-0  md:static lg:static shadow-black shadow md:shadow-none lg:shadow-none  bg-white ${isOpen ? 'block' : 'hidden'} md:flex md:items-center md:w-auto lg:flex lg:items-center lg:w-auto`}
                >
                    <ul className="flex flex-col items-center md:flex-row md:space-x-8 lg:flex-row lg:space-x-8">
                        {/* <li className='w-full text-center'>
                            <a
                                href="#"
                                className="block lg:w-72 w-full py-6 px-6 lg:py-2 lg:px-3 text-black font-medium lg:font-medium  transition-transform hover:scale-110 lg:hover:text rounded-md text-lg lg:text-sm "
                            >
                                FILTRAR PENDIENTES DE ENTREGA
                            </a>
                        </li> */}
                        <li className='w-full text-center'>
                            <button
                                className="lg:w-32 w-full  shadow-black shadow-sm border border-black bg-red-600 hover:bg-red-700 block py-3 px-6 lg:py-1.5 lg:px-3 text-white font-medium lg:font-medium  lg:hover:text rounded-sm text-lg lg:text-base "
                                onClick={logout}
                            >
                                SALIR
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}
