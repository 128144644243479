import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from "react"
import ModalBotonesOpciones from '../Modales/ModalBotonesOpciones'
import ModalDetalleRemito from '../Modales/ModalDetalleRemito'
import ModalRegistrarEntrega from '../Modales/ModalRegistrarEntrega'
import tilde from '../../../images/tilde.png'
import menu from '../../../images/menu.png'
import wapp from '../../../images/wapp.png'
import ToastEntregaCorrecta from '../Toast/ToastEntregaCorrecta'
import MensajeTokenReparto from '../MensajeTokenReparto/MensajeTokenReparto';
import ModalDetalleEntrega from '../Modales/ModalDetalleEntrega';


export default function TablaRemitoRepartidor() {
  const navigate = useNavigate();
  const [mensajeError, setMensajeError] = useState('');
  const [toggleMenu, setToggleMenu] = useState(false);
  const [remitosRepartidor, setRemitosRepartidor] = useState([])
  // estado para el checbok
  const [filtrarPendientes, setFiltrarPendientes] = useState(false);
  const [modalMenu, setModalMenu] = useState(false)
  const [modalDetalle, setModalDetalle] = useState(false);
  const [modalRegistrarEntrega, setModalRegistrarEntrega] = useState(false);
  const [modalDetalleEntrega, setModalDetalleEntrega] = useState(false)
  const [selectedRemito, setSelectedRemito] = useState(null)
  const [avisoEntregaCorrecta, setAvisoEntregaCorrecta] = useState(false)

  useEffect(() => {
    fetchData()
  }, [avisoEntregaCorrecta])

  const fetchData = async () => {
    const token = localStorage.getItem('auth_usuario');
    const Codcomisionista = localStorage.getItem('Codcomisionista');
    const NombreRepartidor = localStorage.getItem('NombreRepartidor');

    if (!token || !Codcomisionista || !NombreRepartidor) {
      localStorage.removeItem('auth_usuario')
      localStorage.removeItem('Codcomisionista')
      localStorage.removeItem('NombreRepartidor')
      navigate('/loginReparto');
      return;
    }

    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/repartidor/remitos/${Codcomisionista}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })
      setRemitosRepartidor(response?.data)

    } catch (error) {
      setMensajeError(error.response?.data?.message || 'Ocurrió un error al obtener los remitos.');
      setToggleMenu(!toggleMenu)
      setTimeout(() => {
        localStorage.removeItem('auth_usuario');
        localStorage.removeItem('Codcomisionista');
        localStorage.removeItem('NombreRepartidor')
        navigate('/loginReparto');
      }, 10000);
    }
  }

  const handleOpenModalMenu = (remito) => {
    setSelectedRemito(remito)
    setModalMenu(true)
  }

  const handleCloseModalMenu = () => {
    setModalMenu(false)
    setSelectedRemito(null)
  }

  const handleOpenModalDetalle = () => {
    setModalDetalle(true);
    setModalMenu(false);
  };

  const handleCloseModalDetalle = () => {
    setModalDetalle(false);
  };

  const handleOpenModalRegistrarEntrega = () => {
    setModalRegistrarEntrega(true)
    setModalMenu(false)
  }

  const handleCloseModalRegistrarEntrega = () => {
    setModalRegistrarEntrega(false)
  }

  const handleOpenModalDetalleEntrega = () => {
    setModalDetalleEntrega(true)
    setModalMenu(false)
  }

  const handleCloseModalDetalleEntrega = () => {
    setModalDetalleEntrega(false)
  }

  const NombreRepartidor = localStorage.getItem('NombreRepartidor')
  const enviarWapp = (rem) => {
    const { Movstock, CelNumero } = rem;

    // Verifica si hay un número de celular y datos en Movstock
    if (!CelNumero || CelNumero?.length === 0 || !Movstock || Movstock?.length === 0) {
      alert("Número de celular o detalle de Remito no disponible!");
      return;
    }

    // Construye el detalle de los artículos
    const detallesArticulos = Movstock?.map(
      (item) => `- ${item?.Codarticulo}: ${item?.Descarticulo?.trim()} (Cantidad: ${item?.Cantidad})`
    ).join('\n');

    // Construye el mensaje completo
    const message = `*CORRALON ALBERDI*\nInforma entrega de mercadería:\nComisionista: ${NombreRepartidor}\n\n${detallesArticulos}\n\n*Muchas gracias por su compra!*`;
    const encodedMessage = encodeURIComponent(message);
    const whatsappUrl = `https://wa.me/${CelNumero}?text=${encodedMessage}`;

    // Abre el enlace en una nueva pestaña
    window.open(whatsappUrl, "_blank");
  };

  const handleInputFiltrarPendientes = (e) => {
    setFiltrarPendientes(e.target.checked);
  };

  const remitosFiltrados = filtrarPendientes
    ? remitosRepartidor?.filter(
      (rem) => rem?.Entregas?.[0]?.Recibido !== 'SI'
    )
    : remitosRepartidor;

  return (

    <div className="h-full w-full overflow-x-auto">
      <div className="flex justify-center   items-center bg-gray-300 lg:p-0 p-2.5">
        <label className="flex items-center cursor-pointer">
          <input
            className="cursor-pointer w-4 h-4"
            type="checkbox"
            onChange={handleInputFiltrarPendientes}
          />
          <p className="font-semibold ml-2">FILTRAR PENDIENTES DE ENTREGA</p>
        </label>
      </div>

      <table className="table-auto w-full border-collapse border border-gray-300">
        <thead className="bg-black text-white">
          <tr>
            <th className="px-4 py-0 text-center border border-gray-300">Menú</th>
            <th className="px-1 py-0 text-center border border-gray-300">Fecha</th>
            <th className="px-1  py-0 text-center border border-gray-300">N°Comprobante</th>
            <th className="px-16 py-0 text-center border border-gray-300">Nombre</th>
            <th className="lg:px-4 px-12 py-0 text-center border border-gray-300">Entregado</th>
            <th className="px-4 py-0 text-center border border-gray-300">Wapp</th>
          </tr>
        </thead>
        <tbody className="bg-white">
          {remitosFiltrados?.map((rem, index) => (
            <tr key={index} className={`border border-gray-300 hover:bg-gray-200 text-sm ${rem?.Entregas?.[0]?.Recibido === 'SI' ? 'bg-orange-200' : ''}`}>
              <td className="px-4 lg:py-1.5 py-1 text-center items-center justify-center flex">
                <button onClick={() => handleOpenModalMenu(rem)} className='' title='Abir Menú' >
                  <img src={menu} alt="menu" className='w-7 h-7 ' />
                </button>
              </td>
              <td className="px-1 lg:py-0 py-1 text-center">{rem?.Fecha}</td>
              <td className="px-3 lg:py-0 py-1 text-center">{rem?.Talonario}-{rem?.Nrocomprobante}</td>
              <td className="px-1 lg:py-0 py-1 text-left">{rem?.Nombre}</td>
              <td className="px-1 lg:py-0 py-1 text-center" >
                {rem?.Entregas?.[0]?.Recibido === 'SI' ?
                  <div className='flex justify-center items-center'>
                    {/* <img src={tilde} alt="tilde" className="w-8 h-8 mr-1" title='Entregado' /> */}
                    <p >{new Date(rem?.Entregas?.[0]?.createdAt)?.toLocaleString('es-ES', { hour12: false })}</p>
                  </div>
                  : ''}

              </td>

              <td className="text-center">
                <button
                  onClick={() => enviarWapp(rem)}
                  title="Enviar Wapp"
                  disabled={rem?.CelNumero?.length === 0 || rem?.Entregas?.[0]?.Recibido !== 'SI'}
                >
                  <img
                    src={wapp}
                    alt="wapp"
                    className={`w-8 h-8 ${rem?.CelNumero?.length === 0 || rem?.Entregas?.[0]?.Recibido !== 'SI' ? 'opacity-0' : ''}`}
                  />
                </button>
              </td>

            </tr>
          ))}
        </tbody>
      </table>

      {/* Modal Botones de Opciones*/}
      {modalMenu && (
        <ModalBotonesOpciones
          selectedRemito={selectedRemito}
          handleCloseModalMenu={handleCloseModalMenu}
          handleOpenModalDetalle={handleOpenModalDetalle}
          handleOpenModalRegistrarEntrega={handleOpenModalRegistrarEntrega}
          handleOpenModalDetalleEntrega={handleOpenModalDetalleEntrega} />
      )}

      {/* Segundo Modal (Detalle del remito) */}
      {modalDetalle && (<ModalDetalleRemito selectedRemito={selectedRemito} handleCloseModalDetalle={handleCloseModalDetalle} />)}

      {/* Tercel Modal Formulario para registrar la Entrega */}
      {modalRegistrarEntrega && (<ModalRegistrarEntrega selectedRemito={selectedRemito} handleCloseModalRegistrarEntrega={handleCloseModalRegistrarEntrega} setAvisoEntregaCorrecta={setAvisoEntregaCorrecta} />)}

      {modalDetalleEntrega && (<ModalDetalleEntrega selectedRemito={selectedRemito} handleCloseModalDetalleEntrega={handleCloseModalDetalleEntrega} />)}

      {avisoEntregaCorrecta && (<ToastEntregaCorrecta setAvisoEntregaCorrecta={setAvisoEntregaCorrecta} />)}

      {toggleMenu && <MensajeTokenReparto mensajeError={mensajeError} />}

    </div>
  )
}