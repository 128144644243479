
export default function BotoneraInicio() {
  return (
    <main className="flex flex-col lg:space-y-8 space-y-12 min-h-screen w-full items-center justify-center bg-white">

      <div className="flex flex-col lg:space-y-4 space-y-8 items-center justify-center shadow shadow-black p-10 ">
        <a href="/loginAdministrador" className="flex  text-center justify-center items-center lg:h-12 lg:w-56 h-16 w-64 overflow-hidden rounded-lg bg-green-500 hover:bg-green-600 text-xl font-bold text-white cursor-pointer border border-black">
          ADMINISTRADOR
        </a>
        <a href="/loginReparto" className="flex  text-center justify-center items-center lg:h-12 lg:w-56 h-16 w-64 overflow-hidden rounded-lg bg-blue-500 hover:bg-blue-600 text-xl font-bold text-white cursor-pointer border border-black">
          REPARTO
        </a>
        <a href="/loginPrecios" className="flex  text-center justify-center items-center lg:h-12 lg:w-56 h-16 w-64 overflow-hidden rounded-lg bg-orange-500 hover:bg-orange-600 text-xl font-bold text-white cursor-pointer border border-black">
          PRECIOS
        </a>
      </div>

    </main>
  )
}