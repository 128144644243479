

export default function Paginacion({ articulos, handlePreviousPage, page, totalArticulos, itemsPerPage, handleNextPage }) {
    return (
        <>
            {/* Controles de paginación */}
            {articulos?.length > 0 && (

                <div className='flex justify-center items-center  mt-5 mb-4'>
                    <button
                        onClick={handlePreviousPage}
                        className='font-semibold border border-black px-2 py-1 rounded-sm mr-2 bg-red-500 lg:w-36 w-24 text-white cursor-pointer'
                        disabled={page === 1}
                    >
                        Anterior
                    </button>
                    <span className='font-semibold text-white'>Página {page} de {Math.ceil(totalArticulos / itemsPerPage)}</span>
                    <button
                        onClick={handleNextPage}
                        className='font-semibold border border-black px-2 py-1 rounded-sm ml-2 bg-blue-500 lg:w-36 w-24 text-white cursor-pointer'
                        disabled={page === Math.ceil(totalArticulos / itemsPerPage)}
                    >
                        Siguiente
                    </button>
                </div>
            )}
        </>
    )
}
