import { useNavigate } from 'react-router-dom';
import Articulos from '../SeccionPreciosArticulos/Articulos/Articulos';

export default function LayoutPrecios() {
    const navigate = useNavigate();

    const logout = () => {
        localStorage.removeItem('auth_usuario');
        navigate('/loginPrecios');
    }

    return (

        <div className='h-screen grid grid-cols-1 bg-gray-600'>
            <div className='flex justify-end lg:h-10 h-12 lg:mb-0 mb-2 '>
                <button
                    className='bg-red-500 px-6 py-0 font-semibold lg:w-72 w-full text-white text-xl border border-black '
                    onClick={logout}
                >
                    SALIR
                </button>
            </div>

            <div className='h-full flex justify-center items-center bg-gray-600'>
                <Articulos />
            </div>


        </div>
    );
}
