import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import { useState, useEffect, useRef } from "react"
// import tilde from '../../../images/tilde.png'
import menu from '../../../images/menu.png'
import wapp from '../../../images/wapp.png'
import flechaAbajo from '../../../images/flecha-abajo.png'
import flechaArriba from '../../../images/flecha-arriba.png'
import ModalBotonesOpcionesAdministrador from '../ModalesAdministrador/ModalBotonesOpcionesAdministrador';
import ModalDetalleRemitoAdministrador from '../ModalesAdministrador/ModalDetalleRemitoAdministrador';
import ModalRegistrarEntregaAdministrador from '../ModalesAdministrador/ModalRegistrarEntregaAdministrador';
import ModalDetalleEntregaAdministrador from '../ModalesAdministrador/ModalDetalleEntregaAdministrador';
import ToastEntregaCorrectaAdministrador from '../ToastAdministrador/ToastEntregaCorrectaAdministrador';
import MensajeTokenAdministrador from '../MensajeTokenAdministrador/MensajeTokenAdministrador';
import FiltroFechas from '../FiltroFechas/FiltroFechas';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { generaPdfRemitosEntregados } from '../../../services/generarPdfRemitosEntregados';


export default function TablaRemitosEntregadosAdministrador() {
    const navigate = useNavigate();
    const [mensajeError, setMensajeError] = useState('');
    const [toggleMenu, setToggleMenu] = useState(false);
    const [remitosAdministrador, setRemitosAdministrador] = useState([])
    const [repartidores, setRepartidores] = useState([])
    const [modalMenu, setModalMenu] = useState(false)
    const [modalDetalle, setModalDetalle] = useState(false);
    const [modalRegistrarEntrega, setModalRegistrarEntrega] = useState(false);
    const [modalDetalleEntrega, setModalDetalleEntrega] = useState(false)
    const [selectedRemito, setSelectedRemito] = useState(null)
    const [avisoEntregaCorrecta, setAvisoEntregaCorrecta] = useState(false)

    // botones para subir y bajar
    const [showScrollButtons, setShowScrollButtons] = useState(false);
    // para guardar el codigo del repartidor seleccionado para el filtro
    const [repartidorSeleccionado, setRepartidorSeleccionado] = useState("");
    // ESTAS SON LAS FECHAS SELECIONADAS
    const [fechaDesde, setFechaDesde] = useState('');
    const [fechaHasta, setFechaHasta] = useState('');
    // REGISTROS FILTRADOS POR FECHA
    const [registrosFiltrados, setRegistrosFiltrados] = useState([]);
    // PARA CONTROLAR EL ESTADO DEL BOTON DE IMPIRMIR
    // const [estadoImprimir, setEstadoImprimir] = useState(false)
    // estos remitos son con los cuels se van a generar el pdf
    const [remitosPdf, setRemitosPdf] = useState([])

    //   ACA OBTENGO LOS REMITOS PENDIENTES QUE SE MOSTRARAN EN ESTA TABLA
    const remitosEntregados = remitosAdministrador?.filter((rem) => rem?.Entregas?.[0]?.Recibido == 'SI')
    const remitosPdfEntregados = remitosPdf?.filter((rem) => rem?.Entregas?.[0]?.Recibido == 'SI')


    const tableContainerRef = useRef(null);

    useEffect(() => {
        fetchData()

        //  ESTO ES PARA CETEAR LOS INPUT CON LAS FECHAS DEL PRIMER DIA DEL MES Y EL ULTIMO DIA DEL MES ACTUAL
        const now = new Date();
        const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
        const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);

        const formatDate = (date) => date?.toISOString().split('T')[0];

        setFechaDesde(formatDate(firstDay));
        setFechaHasta(formatDate(lastDay));

    }, [avisoEntregaCorrecta])

    useEffect(() => {
        if (tableContainerRef.current) {
            const { scrollHeight, clientHeight } = tableContainerRef.current;
            setShowScrollButtons(scrollHeight > clientHeight);
            tableContainerRef.current.scrollTop = scrollHeight;
        }
    }, [remitosEntregados, repartidorSeleccionado]);

    const fetchData = async () => {
        const token = localStorage.getItem('auth_usuario');
        const NombreAdministrador = localStorage.getItem('NombreAdministrador');

        if (!token || !NombreAdministrador) {
            localStorage.removeItem('auth_usuario')
            localStorage.removeItem('NombreAdministrador')
            navigate('/loginAdministrador');
            return;
        }

        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/remito/administrador`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            })
            setRemitosAdministrador(response?.data)
            setRemitosPdf(response?.data)

            const responseRepartidores = await axios.get(`${process.env.REACT_APP_BASE_URL}/repartidor`)
            setRepartidores(responseRepartidores?.data)

        } catch (error) {
            setMensajeError(error.response?.data?.message || 'Ocurrió un error al obtener los remitos.');
            setToggleMenu(!toggleMenu)
            setTimeout(() => {
                localStorage.removeItem('auth_usuario');
                localStorage.removeItem('NombreAdministrador');
                navigate('/loginAdministrador');
            }, 10000);
        }
    }

    const handleOpenModalMenu = (remito) => {
        setSelectedRemito(remito)
        setModalMenu(true)
    }

    const handleCloseModalMenu = () => {
        setModalMenu(false)
        setSelectedRemito(null)
    }

    const handleOpenModalDetalle = () => {
        setModalDetalle(true);
        setModalMenu(false);
    };

    const handleCloseModalDetalle = () => {
        setModalDetalle(false);
    };

    const handleOpenModalRegistrarEntrega = () => {
        setModalRegistrarEntrega(true)
        setModalMenu(false)
    }

    const handleCloseModalRegistrarEntrega = () => {
        setModalRegistrarEntrega(false)
    }

    const handleOpenModalDetalleEntrega = () => {
        setModalDetalleEntrega(true)
        setModalMenu(false)
    }

    const handleCloseModalDetalleEntrega = () => {
        setModalDetalleEntrega(false)
    }

    const NombreAdministrador = localStorage.getItem('NombreAdministrador')
    const enviarWapp = (rem) => {
        const { Movstock, CelNumero } = rem;

        // Verifica si hay un número de celular y datos en Movstock
        if (!CelNumero || CelNumero?.length === 0 || !Movstock || Movstock?.length === 0) {
            alert("Número de celular o detalle de Remito no disponible!");
            return;
        }

        // Construye el detalle de los artículos
        const detallesArticulos = Movstock?.map(
            (item) => `- ${item?.Codarticulo}: ${item?.Descarticulo?.trim()} (Cantidad: ${item?.Cantidad})`
        ).join('\n');

        // Construye el mensaje completo
        const message = `*CORRALON ALBERDI*\nInforma entrega de mercadería:\nEncargado: ${NombreAdministrador}\n\n${detallesArticulos}\n\n*Muchas gracias por su compra!*`;
        const encodedMessage = encodeURIComponent(message);
        const whatsappUrl = `https://wa.me/${CelNumero}?text=${encodedMessage}`;

        // Abre el enlace en una nueva pestaña
        window.open(whatsappUrl, "_blank");
    };

    const scrollToTop = () => {
        if (tableContainerRef.current) {
            tableContainerRef.current.scrollTop = 0;
        }
    };

    const scrollToBottom = () => {
        if (tableContainerRef.current) {
            tableContainerRef.current.scrollTop = tableContainerRef.current.scrollHeight;
        }
    };

    const remitosFiltrados = repartidorSeleccionado ? remitosEntregados?.filter((rem) => rem?.Codcomisionista == repartidorSeleccionado) : remitosEntregados;


    const handleRepartidorChange = (e) => {
        const codigoRepartidor = e.target.value;
        setRepartidorSeleccionado(codigoRepartidor);
    }

    const handleFiltrarClick = () => {
        setRepartidorSeleccionado('')
        // ACA SE FILTRAN LOS REGISTROS QUE SE MUESTRAN EN LA TABLA
        const registrosFiltrados = remitosEntregados?.filter(registro => {
            const fecha = new Date(registro?.FechaFormateada);
            return fecha >= new Date(fechaDesde) && fecha <= new Date(fechaHasta);
        });
        setRegistrosFiltrados(registrosFiltrados?.length > 0 ? registrosFiltrados : []);
        setRemitosPdf(registrosFiltrados?.length > 0 ? registrosFiltrados : []);

        // Mostrar mensaje si no hay registros filtrados
        if (registrosFiltrados?.length <= 0) {
            setRemitosPdf(remitosEntregados)
            toast.error("NO HAY REGISTROS EN EL RANGO DE FECHAS", {
                position: 'top-center',
                autoClose: 3000,
            });
        }

    };

    const handleImprimirClick = () => {
        generaPdfRemitosEntregados(remitosPdfEntregados, fechaDesde, fechaHasta);
    };

    return (


        <div className="h-full w-full overflow-hidden">
            <div className="flex-shrink-0 flex justify-center items-center bg-gwhite mt-0 lg:mt-0.5 -mb-0.5 bg-gray-300">
                <div className='flex-grow lg:flex justify-center items-center mb-0.5 lg:mb-2 mt-2 p-1 lg:p-1 lg:mt-0'>
                    <FiltroFechas handleFiltrarClick={handleFiltrarClick} handleImprimirClick={handleImprimirClick} setFechaDesde={setFechaDesde} setFechaHasta={setFechaHasta} fechaDesde={fechaDesde} fechaHasta={fechaHasta} />
                    {/* Contenedor de Toastify */}
                    <ToastContainer />
                </div>
            </div>
            <div className="flex  justify-center  items-center bg-gray-300 lg:p-0 p-2.5">
                <div className='flex justify-center items-center mt-1 lg:mt-0 mb-1.5'>
                    <select
                        className=" p-0.5 font-semibold  text-center border border-black"
                        value={repartidorSeleccionado}
                        onChange={handleRepartidorChange}
                    >
                        <option value="">FILTRAR POR REPARTIDOR</option>
                        {repartidores?.map((rep, index) => (
                            <option key={index} value={rep?.Codigo}>
                                {rep?.Nombre}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <div className='bg-yellow-300 border border-black'>
                <p className='text-center font-semibold'>REMITOS ENTREGADOS</p>
            </div>
            <div className="h-[calc(100vh-14rem)] overflow-auto" ref={tableContainerRef}>
                <table className="table-auto w-full border-collapse border border-gray-300">
                    <thead className="bg-black text-white sticky top-0">
                        <tr>
                            <th className="px-4 py-0 text-center border border-gray-300">Menú</th>
                            <th className="px-1 py-0 text-center border border-gray-300">Fecha</th>
                            <th className="px-1  py-0 text-center border border-gray-300">N°Comprobante</th>
                            <th className="px-14 py-0 text-center border border-gray-300">Nombre</th>
                            <th className="lg:px-4 px-20 py-0 text-center border border-gray-300">Entregado</th>
                            <th className="px-4 py-0 text-center border border-gray-300">Wapp</th>
                        </tr>
                    </thead>
                    <tbody className="bg-white">
                        {registrosFiltrados?.length > 0 ? (
                            registrosFiltrados?.map((rem, index) => (
                                // <tr key={index} className="border border-gray-300 hover:bg-gray-200 text-sm">
                                <tr key={index} className={`border border-gray-300 hover:bg-gray-200 text-sm ${rem?.Entregas?.[0]?.Recibido === 'SI' ? 'bg-orange-200' : ''}`}>
                                    <td className="px-4 lg:py-1.5 py-1 text-center items-center justify-center flex">
                                        <button
                                            onClick={() => handleOpenModalMenu(rem)}
                                            className=""
                                            title="Abrir Menú"
                                        >
                                            <img src={menu} alt="menu" className="w-7 h-7 " />
                                        </button>
                                    </td>
                                    <td className="px-1 lg:py-0 py-1 text-center">{rem?.Fecha}</td>
                                    <td className="px-1 lg:py-0 py-1 text-center">
                                        {rem?.Talonario}-{rem?.Nrocomprobante}
                                    </td>
                                    <td className="px-1 lg:py-0 py-1 text-left">{rem?.Nombre}</td>
                                    <td className="px-1 lg:py-0 py-1 text-center text-xs lg:text-base">
                                        {rem?.Entregas?.[0]?.Recibido === "SI" ? (
                                            <div className="flex justify-center items-center">
                                                {/* <img
                          src={tilde}
                          alt="tilde"
                          className="w-8 h-8 mr-4"
                          title="Entregado"
                        /> */}
                                                <p className="mr-2">
                                                    {new Date(rem?.Entregas?.[0]?.createdAt)?.toLocaleString(
                                                        "es-ES",
                                                        { hour12: false }
                                                    )}
                                                </p>
                                                <p>
                                                    {rem?.Entregas?.[0]?.nombreRepartidor ||
                                                        rem?.Entregas?.[0]?.nombreAdministrador}
                                                </p>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </td>

                                    <td className="text-center">
                                        <button
                                            onClick={() => enviarWapp(rem)}
                                            title="Enviar Wapp"
                                            disabled={
                                                rem?.CelNumero?.length === 0 ||
                                                rem?.Entregas?.[0]?.Recibido !== "SI"
                                            }
                                        >
                                            <img
                                                src={wapp}
                                                alt="wapp"
                                                className={`w-8 h-8 ${rem?.CelNumero?.length === 0 ||
                                                    rem?.Entregas?.[0]?.Recibido !== "SI"
                                                    ? "opacity-0"
                                                    : ""
                                                    }`}
                                            />
                                        </button>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            remitosFiltrados?.map((rem, index) => (
                                // <tr key={index} className="border border-gray-300 hover:bg-gray-200 text-sm">
                                     <tr key={index} className={`border border-gray-300 hover:bg-gray-200 text-sm ${rem?.Entregas?.[0]?.Recibido === 'SI' ? 'bg-orange-200' : ''}`}>
                                    <td className="px-4 lg:py-1.5 py-1 text-center items-center justify-center flex">
                                        <button
                                            onClick={() => handleOpenModalMenu(rem)}
                                            className=""
                                            title="Abrir Menú"
                                        >
                                            <img src={menu} alt="menu" className="w-7 h-7 " />
                                        </button>
                                    </td>
                                    <td className="px-4 lg:py-0 py-1 text-center">{rem?.Fecha}</td>
                                    <td className="px-4 lg:py-0 py-1 text-center">
                                        {rem?.Talonario}-{rem?.Nrocomprobante}
                                    </td>
                                    <td className="px-4 lg:py-0 py-1 text-left">{rem?.Nombre}</td>
                                    <td className="px-4 lg:py-0 py-1 text-center text-xs lg:text-base">
                                        {rem?.Entregas?.[0]?.Recibido === "SI" ? (
                                            <div className="flex justify-center items-center">
                                                {/* <img
                          src={tilde}
                          alt="tilde"
                          className="w-8 h-8 mr-4"
                          title="Entregado"
                        /> */}
                                                <p className="mr-2">
                                                    {new Date(rem?.Entregas?.[0]?.createdAt)?.toLocaleString(
                                                        "es-ES",
                                                        { hour12: false }
                                                    )}
                                                </p>
                                                <p>
                                                    {rem?.Entregas?.[0]?.nombreRepartidor ||
                                                        rem?.Entregas?.[0]?.nombreAdministrador}
                                                </p>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </td>

                                    <td className="text-center">
                                        <button
                                            onClick={() => enviarWapp(rem)}
                                            title="Enviar Wapp"
                                            disabled={
                                                rem?.CelNumero?.length === 0 ||
                                                rem?.Entregas?.[0]?.Recibido !== "SI"
                                            }
                                        >
                                            <img
                                                src={wapp}
                                                alt="wapp"
                                                className={`w-8 h-8 ${rem?.CelNumero?.length === 0 ||
                                                    rem?.Entregas?.[0]?.Recibido !== "SI"
                                                    ? "opacity-0"
                                                    : ""
                                                    }`}
                                            />
                                        </button>
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>

                {showScrollButtons && (
                    <>
                        <button
                            onClick={scrollToBottom}
                            className="fixed bottom-1 lg:right-6 right-1 bg-transparent p-0 m-0 border-none cursor-pointer"
                            aria-label="Desplazar hacia abajo">
                            <img src={flechaAbajo} alt="Desplazar hacia abajo" className="lg:w-7 lg:h-7 w-10 h-10 transition-transform hover:scale-95" />
                        </button>
                        <button
                            onClick={scrollToTop}
                            className="fixed bottom-1 lg:right-16  bg-transparent p-0 m-0 border-none cursor-pointer"
                            aria-label="Desplazar hacia arriba">
                            <img src={flechaArriba} alt="Desplazar hacia arriba" className="lg:w-7 lg:h-7 w-10 h-10 transition-transform hover:scale-95" />
                        </button>
                    </>
                )}

            </div>

            {/* Modal Botones de Opciones*/}
            {modalMenu && (
                <ModalBotonesOpcionesAdministrador
                    selectedRemito={selectedRemito}
                    handleCloseModalMenu={handleCloseModalMenu}
                    handleOpenModalDetalle={handleOpenModalDetalle}
                    handleOpenModalRegistrarEntrega={handleOpenModalRegistrarEntrega}
                    handleOpenModalDetalleEntrega={handleOpenModalDetalleEntrega} />
            )}

            {/* Segundo Modal (Detalle del remito) */}
            {modalDetalle && (<ModalDetalleRemitoAdministrador selectedRemito={selectedRemito} handleCloseModalDetalle={handleCloseModalDetalle} />)}

            {/* Tercel Modal Formulario para registrar la Entrega */}
            {modalRegistrarEntrega && (<ModalRegistrarEntregaAdministrador selectedRemito={selectedRemito} handleCloseModalRegistrarEntrega={handleCloseModalRegistrarEntrega} setAvisoEntregaCorrecta={setAvisoEntregaCorrecta} />)}

            {modalDetalleEntrega && (<ModalDetalleEntregaAdministrador selectedRemito={selectedRemito} handleCloseModalDetalleEntrega={handleCloseModalDetalleEntrega} />)}

            {avisoEntregaCorrecta && (<ToastEntregaCorrectaAdministrador setAvisoEntregaCorrecta={setAvisoEntregaCorrecta} />)}

            {toggleMenu && <MensajeTokenAdministrador mensajeError={mensajeError} />}

        </div>

    )
}