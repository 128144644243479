
export default function ToastErrorImagenOFirma({ mensajeError, handleCloseToastErrorImagenOFirma }) {
    return (
        <div className="flex justify-center items-center fixed inset-0 z-50 bg-opacity-80 bg-gray-800">
            <div className="max-w-xs  bg-red-500  text-white" role="alert">
                <div className="flex p-4 font-semibold text-lg">
                    {mensajeError}
                    <div className="ml-8">
                        <button onClick={handleCloseToastErrorImagenOFirma} type="button" className="inline-flex flex-shrink-0 justify-end items-center border border-white px-2   ">
                            <p className="flex justify-end text-white text-xl">X</p>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
