import impresora from '../../../images/impresora.png'
import filtrar from '../../../images/filtrar.png'
import disket from '../../../images/disket.png'

export default function FiltroFechas({ setFechaDesde, setFechaHasta, fechaHasta, fechaDesde, handleFiltrarClick,  handleImprimirClick }) {



    const handleChangeFechaVtoDesde = (e) => {
        setFechaDesde(e.target.value);
    };

    const handleChangeFechaVtoHasta = (e) => {
        setFechaHasta(e.target.value);
    };

    return (

        <>
            <div className='flex  mb-4 lg:mb-0'>
                <div className="text-center">
                    <label htmlFor="desde" className="font-bold text-sm cursor-pointer "> DESDE </label>
                    <input value={fechaDesde} id="desde" type="date" className="bg-white text-center rounded-md cursor-pointer border-black border font-bold   mr-2" onChange={handleChangeFechaVtoDesde} />
                </div>
                <div className="text-center">
                    <label htmlFor="hasta" className="font-bold text-sm cursor-pointer"> HASTA </label>
                    <input value={fechaHasta} id="hasta" type="date" className="bg-white text-center rounded-md cursor-pointer border-black border font-bold mr-2" onChange={handleChangeFechaVtoHasta} />
                </div>
            </div>

            <div className='flex justify-center items-center '>
                <div className='text-center'>
                    <button title='Filtrar' onClick={handleFiltrarClick} className="pl-2 pr-8 lg:pr-4">
                        <img src={filtrar} alt="filtro" className='w-10 h-9 lg:w-8 lg:h-7' />
                    </button>
                    <button title="Imprimir" onClick={handleImprimirClick} className='pr-8 lg:pr-4'>
                        <img src={impresora} alt="impresora" className='w-10 h-9 lg:w-8 lg:h-7 ' />
                    </button>
                    <button title="Disket" >
                        <img src={disket} alt="disket" className='w-10 h-9 lg:w-8 lg:h-7' />
                    </button>
                </div>
            </div>

        </>

    );
}
