import { Outlet } from 'react-router-dom';
import NavbarAdministrador from '../NavbarAdministrador/NavbarAdministrador';

export default function LayoutAdministrador() {
  return (
    <div className="h-screen flex flex-col bg-white">
      {/* Navbar siempre en la parte superior */}
      <div className="fixed top-0 left-0 w-full z-10">
        <NavbarAdministrador />
      </div>

      {/* Contenido dinámico dentro de <Outlet> */}
      <div className="flex-grow  mt-[4.5rem] lg:mt-[3rem] overflow-hidden">
        <Outlet />
      </div>

      {/* Pie de página o espacio reservado */}
      <div className=" lg:p-5 p-6 bg-black">
        {/* Puedes añadir contenido aquí si es necesario */}
      </div>
    </div>
  )
}
