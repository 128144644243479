import tacho from '../../../../images/tacho.png';


export default function InputBusquedaArticulo({ searchTerm, handleSearchChange, clearInput }) {
    return (
        <div className='sticky top-0 z-10 p-1 bg-gray-600  flex justify-center w-full'>
            <div className='relative w-full lg:w-96'>
                <input
                    type="text"
                    className='rounded-sm px-2 font-mono w-full text-center text-xl h-11 lg:h-9 '  
                    placeholder='BUSCAR ARTÍCULO...'
                    autoComplete="off"
                    value={searchTerm}
                    onChange={handleSearchChange}
                />
                <button onClick={clearInput} className='absolute right-1 top-1/2 transform -translate-y-1/2  p-1'>
                    <img src={tacho} alt="Tachito" className='w-11 h-9 lg:w-10 lg:h-8' />
                </button>
            </div>
           
        </div>
    );
}