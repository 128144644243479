

export default function ModalDetalleRemito({ selectedRemito, handleCloseModalDetalle }) {

    return (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-70 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg p-4 lg:p-6 w-11/12 sm:w-4/5 md:w-1/2 max-w-full shadow-lg relative max-h-[80vh] overflow-y-auto">
                <div className="flex justify-end items-center -mt-2">
                    <button onClick={handleCloseModalDetalle} className="bg-red-600 px-4 py-1 text-white font-black rounded-sm">X</button>
                </div>
                
                <h2 className="text-sm lg:text-base font-bold  text-center mt-2">
                    {selectedRemito?.Nombre}
                </h2>
                <h2 className="text-sm lg:text-base font-bold  text-center">
                    {selectedRemito?.Talonario}-{selectedRemito?.Nrocomprobante}
                </h2>
                <p className={`text-center text-sm font-semibold ${selectedRemito?.Entregas?.[0]?.Recibido === 'SI' ? 'text-green-600' : 'text-red-600'}`}>{selectedRemito?.Entregas?.[0]?.Recibido === 'SI' ? 'ENTREGADO' : 'PENDIENTE DE ENTREGA'}</p>

                <table className="table-auto w-full border-collapse border border-gray-300 text-sm md:text-base">
                    <thead className="bg-gray-200">
                        <tr>
                            <th className="px-2 md:px-4 py-0.5 border border-gray-300">Código</th>
                            <th className="px-16 md:px-32 py-0.5 border border-gray-300">Descripción</th>
                            <th className="px-2 md:px-4 py-0.5 border border-gray-300">Cantidad</th>
                        </tr>
                    </thead>
                    <tbody>
                        {selectedRemito?.Movstock?.map((item, index) => (
                            <tr key={index} className="border border-gray-300">
                                <td className="px-2 md:px-4 py-0.5 text-center">{item?.Codarticulo}</td>
                                <td className="px-2 text-xs lg:text-base lg:px-4 py-0.5 text-center whitespace-nowrap">{item?.Descarticulo}</td>
                                <td className="px-2 md:px-4 py-0.5 text-center">{item?.Cantidad}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <button
                    onClick={handleCloseModalDetalle}
                    className="mt-4 px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 w-full font-semibold"
                >
                    CERRAR
                </button>
            </div>
        </div>
    )
}
