import { useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';

const FirmaDigitalAdministrador = ({ setFirma }) => {
  const sigCanvas = useRef(null);

  // Función para borrar la firma
  const clear = () => {
    sigCanvas.current.clear();
    setFirma(''); // Resetea la firma en el estado principal
  };

  // Detectar cambios en el lienzo y actualizar la firma en el estado principal
  const handleEnd = () => {
    const signature = sigCanvas.current.toDataURL("image/png");
    setFirma(signature); // Actualiza la firma en el estado principal
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="bg-white rounded-lg w-full">
        <label className="font-semibold">Firma</label>
        <SignatureCanvas
          ref={sigCanvas}
          penColor="black"
          onEnd={handleEnd} // Detecta cuando el usuario termina de dibujar
          canvasProps={{
            className: 'signature border border-gray-300 rounded-lg w-full h-40',
          }}
        />
        <div className="flex justify-center mt-1">
          <button
            type='button'
            className="p-1 font-semibold border border-black w-full bg-red-500 text-white rounded-sm hover:bg-red-600"
            onClick={clear}
          >
            BORRAR FIRMA
          </button>
        </div>
      </div>
    </div>
  );
};

export default FirmaDigitalAdministrador;
