import axios from "axios"
import { useState, useEffect } from "react"
import { useNavigate } from 'react-router-dom';
import MensajeTokenAdministrador from "../MensajeTokenAdministrador/MensajeTokenAdministrador";



export default function ModalDetalleEntregaAdministrador({ selectedRemito, handleCloseModalDetalleEntrega }) {
    const navigate = useNavigate();

    const { Clave } = selectedRemito
    const [entrega, setEntrega] = useState([])
    const [mensajeError, setMensajeError] = useState('');
    const [toggleMenu, setToggleMenu] = useState(false);


    useEffect(() => {
        fetchData();
    }, [Clave]);


    const fetchData = async () => {
        const token = localStorage.getItem('auth_usuario');
        const NombreAdministrador = localStorage.getItem('NombreAdministrador');
       
        if (!token || !NombreAdministrador ) {
            localStorage.removeItem('auth_usuario')
            localStorage.removeItem('NombreAdministrador')
            navigate('/loginAdministrador');
            return;
        }
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/remito/administrador/detalleentrega/${Clave}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
            setEntrega(response.data);
        } catch (error) {
            setMensajeError(error.response?.data?.message || 'Ocurrió un error al obtener el detalle de entrega.');
            setToggleMenu(!toggleMenu)
            setTimeout(() => {
                localStorage.removeItem('auth_usuario');
                localStorage.removeItem('NombreAdministrador');
                navigate('/loginAdministrador');
            }, 10000);

        }
    };



    return (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg p-4 lg:p-5 w-11/12 sm:w-4/5 lg:w-[30%] max-w-full shadow-lg relative max-h-[80vh] overflow-y-auto">
                <div className="flex flex-col justify-center items-center">
                    {entrega?.Firma && <img src={entrega?.Firma} className="w-full h-72?" alt="Firma" />}
                    {entrega?.Imagen && <img src={entrega?.Imagen} className="w-44 h-44" alt="Imagen" />}
                    {entrega?.Nota && <p>{entrega?.Nota}</p>}
                </div>

                <div className="flex justify-center mt-4">
                    <button
                        onClick={handleCloseModalDetalleEntrega}
                        className="p-1.5 lg:p-1.5 w-full text-xl lg:text-base rounded-sm font-semibold bg-red-600 hover:bg-red-700 border border-black text-white"
                    >
                        CERRAR
                    </button>
                </div>
            </div>
            {toggleMenu && <MensajeTokenAdministrador mensajeError={mensajeError} />}
        </div>
    )
}


