import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import icono from '../../../images/usuario.png';

export default function LoginPrecios() {
    const navigate = useNavigate();

    const [nombre, setNombre] = useState('');
    const [clave, setClave] = useState('');
    const [errorMensaje, setErrorMensaje] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const handleNombreChange = (e) => {
        const nombreIngresado = e.target.value.trim();
        setNombre(nombreIngresado);
        setErrorMensaje('');
    };

    const handleClaveChange = (e) => {
        const claveIngresado = e.target.value.trim();
        setClave(claveIngresado);
        setErrorMensaje('');
    };

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleLogin = async (e) => {
        e.preventDefault();

        if (!nombre || !clave) {
            setErrorMensaje('¡Complete todos los campos!');
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/login/vendedor`, { nombre, clave });
            if (response.status === 200) {
                const { token } = response.data;
                localStorage.setItem('auth_usuario', token);
                navigate('/layoutPrecios');
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                setErrorMensaje(error.response.data.message);
            } else {
                setErrorMensaje('Error al iniciar sesión.');
            }
        }
    };

    const regresarHome = () => {
        navigate('/')
    }
    return (
        <div className="bg-white h-screen w-screen flex justify-center items-center">
            <div className="w-full md:w-1/2 flex flex-col items-center">
                <img src={icono} alt="usuario" className="w-20 h-20 mb-8" />
                <form onSubmit={handleLogin} className="w-72 lg:w-96">
                    <div className="mb-6">
                        <input
                            type="text"
                            autoComplete="off"
                            name="usuario"
                            id="usuario"
                            className="w-full py-4 px-8 bg-slate-200 placeholder:font-semibold rounded hover:ring-1 outline-blue-500"
                            placeholder="Usuario..."
                            value={nombre}
                            onChange={handleNombreChange}
                            required
                        />
                    </div>

                    <div className="mb-6 relative">
                        <input
                            type={showPassword ? "text" : "password"}
                            name="password"
                            id="password"
                            className="w-full py-4 px-8 bg-slate-200 placeholder:font-semibold rounded hover:ring-1 outline-blue-500"
                            placeholder="Contraseña..."
                            value={clave}
                            onChange={handleClaveChange}
                            required
                        />
                        <button
                            type="button"
                            onClick={toggleShowPassword}
                            className="absolute right-4 top-4"
                        >
                            {showPassword ? '👁' : '👁‍🗨'}
                        </button>
                    </div>

                    {errorMensaje && (
                        <p className="text-red-500 font-semibold text-center mb-2 -mt-2 -lg:mt-4">{errorMensaje}</p>
                    )}

                    <button
                        type="submit"
                        className="py-4 bg-blue-500 w-full rounded text-blue-50 font-bold hover:bg-blue-700"
                    >
                        INGRESAR A LISTA DE PRECIOS
                    </button>
                </form>
                <div className='lg:w-96 w-72 mt-4'>
                    <button
                        onClick={regresarHome}
                        className="py-4 bg-red-500 w-full rounded text-blue-50 font-bold hover:bg-red-700"
                    >
                        VOLVER
                    </button>
                </div>
            </div>
        </div>
    )
}
