

export default function ModalBotonesOpciones({ selectedRemito, handleCloseModalMenu, handleOpenModalDetalle, handleOpenModalRegistrarEntrega, handleOpenModalDetalleEntrega }) {
    return (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg p-6 lg:w-[20%] w-96 shadow-lg relative">

                <div className="flex flex-col  gap-4">
                    <button
                        className="px-4 py-3 lg:py-1.5 bg-yellow-600 text-white  hover:bg-yellow-700 w-full md:w-auto font-semibold text-lg"
                        onClick={handleOpenModalDetalle}
                    >
                        DETALLE REMITO
                    </button>
                    {
                        selectedRemito?.Entregas?.[0]?.Recibido === 'SI' ?
                            <button
                                className='px-4 py-3 lg:py-1.5 bg-blue-600 text-white  hover:bg-blue-700 w-full md:w-auto font-semibold text-lg'
                                onClick={handleOpenModalDetalleEntrega}
                            >
                                DETALLE DE  ENTREGA
                            </button> :
                            <button
                                className="px-4 py-3 lg:py-1.5 bg-blue-600 text-white  hover:bg-blue-700 w-full md:w-auto font-semibold text-lg"
                                onClick={handleOpenModalRegistrarEntrega}
                            >
                                REGISTRAR ENTREGA
                            </button>
                    }
                </div>

                <button
                    onClick={handleCloseModalMenu}
                    className="mt-10 px-4 py-3 lg:py-1.5 bg-red-600 text-white  hover:bg-red-700 w-full   font-semibold text-lg"
                >
                    CERRAR
                </button>
            </div>
        </div>
    )
}
