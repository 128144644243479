import { Outlet } from 'react-router-dom';
import NavbarReparto from '../NavbarReparto/NavbarReparto';


export default function LayoutReparto() {
    return (
      <div className="h-screen flex flex-col bg-white">
        {/* Navbar siempre en la parte superior */}
        <div className="fixed top-0 left-0 w-full z-10">
          <NavbarReparto />
        </div>
  
        {/* Contenido dinámico dentro de <Outlet> */}
        <div className="flex-grow overflow-auto mt-[4.5rem] lg:mt-[3rem]">
          <Outlet />
        </div>
  
        {/* Pie de página o espacio reservado */}
        <div className="flex-none text-center py-4 bg-gray-200">
          {/* Puedes añadir contenido aquí si es necesario */}
        </div>
      </div>
    );
  }
  
