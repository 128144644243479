import { Route, Routes } from "react-router-dom";
import BotoneraInicio from "./components/BotoneraInicio/BotoneraInicio";
import LoginPrecios from "./components/Precios/LoginPrecios/LoginPrecios";
import LayoutPrecios from "./components/Precios/LayoutPrecios/LayoutPrecios";
import LoginReparto from "./components/Reparto/LoginReparto/LoginReparto";
import LayoutReparto from "./components/Reparto/LayoutReparto/LayoutReparto";
import TablaRemitoRepartidor from "./components/Reparto/TablaRemitosRepartidor/TablaRemitoRepartidor";
import LoginAdministrador from "./components/Administrador/LoginAdministrador/LoginAdministrador";
import LayoutAdministrador from "./components/Administrador/LayoutAdministrador/LayoutAdministrador";
import TablaRemitoAdministrador from "./components/Administrador/TablaRemitoAdministrador/TablaRemitoAdministrador";
import TablaRemitosPendientesAdministrador from "./components/Administrador/TablaRemitoAdministrador/TablaRemitosPendientesAdministrador";
import TablaRemitosEntregadosAdministrador from "./components/Administrador/TablaRemitoAdministrador/TablaRemitosEntregadosAdministrador";



function App() {
  return (
    <div className="">
      <Routes>

        <Route index element={<BotoneraInicio />} />
        <Route path="/home" element={<BotoneraInicio />} />

        {/* seccion administrador */}
        <Route path="/loginAdministrador" element={<LoginAdministrador />} />
        <Route path="/layoutAdministrador" element={<LayoutAdministrador />}>
          <Route index element={<TablaRemitoAdministrador />} />
          <Route path="tablaRemitoAdministrador" element={<TablaRemitoAdministrador />} />
          <Route path="tablaRemitosPendientesAdministrador" element={<TablaRemitosPendientesAdministrador />} />
          <Route path="tablaRemitosEntregadosAdministrador" element={<TablaRemitosEntregadosAdministrador />} />
        </Route>

        {/* seccion repartidor */}
        <Route path="/loginReparto" element={<LoginReparto />} />
        <Route path="/layoutReparto" element={<LayoutReparto />}>
          <Route index element={<TablaRemitoRepartidor />} />
          <Route path="tablaRemitoReparto" element={<TablaRemitoRepartidor />} />
        </Route>

        {/* seccion precios */}
        <Route path="/loginPrecios" element={<LoginPrecios />} />
        <Route path="/layoutPrecios" element={<LayoutPrecios />} />

      </Routes>
    </div>
  );
}

export default App;
