import jsPDF from 'jspdf';
import 'jspdf-autotable';



export const generaPdfRemitos = (remitosPdf, fechaDesde, fechaHasta) => {


    const doc = new jsPDF();

    // Datos de la Empresa para cabecera
    const razonsocial = 'Corralón Alberdi';
    const direccion = 'Domicilio * Nombre Localidad * Telefono';
    const email = 'Correo electronico';

    // Obtener la fecha actual con formato localizado
    const formato = { day: '2-digit', month: '2-digit', year: 'numeric' };
    const fecha = `Fecha: ${new Date().toLocaleDateString('es-ES', formato)}`;

    // Configurar posición de la cabecera
    const posicionEmpresa = 10;
    const posicionDireccionEmail = 15;
    const posicionFecha = 24;
    const posicionTitulo = 24;

    // Agregar los datos de la cabecera al PDF con estilo personalizado
    doc.setFontSize(18); // Tamaño de fuente más grande para el nombre de la empresa
    doc.setFont('bold'); // Establecer la fuente en negrita
    doc.text(razonsocial, 10, posicionEmpresa);
    doc.setFont('normal'); // Restaurar la fuente a la normal después del texto en negrita

    doc.setFontSize(10); // Tamaño de fuente normal para otros datos
    // Colocar dirección y correo electrónico en la misma línea
    doc.text(direccion + ' * ' + email, 10, posicionDireccionEmail);
    doc.setFontSize(12)
    doc.text(fecha, 10, posicionFecha);

    // Establecer un título con estilo personalizado y centrado
    const titulo = 'Resumen de Remitos';
    doc.setFontSize(18); // Tamaño de fuente más grande para el título
    doc.setFont('bold'); // Establecer la fuente en negrita
    doc.setTextColor(0, 0, 255); //color azul
    const tituloWidth = doc.getStringUnitWidth(titulo) * doc.internal.getFontSize() / doc.internal.scaleFactor;
    const tituloX = (doc.internal.pageSize.width - tituloWidth) / 2; // Centrar el título
    doc.text(titulo, tituloX, posicionTitulo);
    doc.setTextColor(0);

    // Dibujar una línea horizontal
    const lineWidth = doc.internal.pageSize.width - 20; // Ancho de la línea, ajusta según sea necesario
    const lineHeight = 0.5; // Grosor de la línea, ajusta según sea necesario
    const lineaY = posicionTitulo + 2; // Ajusta la posición vertical de la línea según tus necesidades
    doc.setDrawColor(0, 0, 0); // Color de la línea (negro)
    doc.setLineWidth(lineHeight);
    doc.line(10, lineaY, 10 + lineWidth, lineaY); // Dibuja la línea horizontal

    // Calcular la posición de la línea para los datos del cliente
    const lineaYCliente = posicionFecha + 5; // Ajusta según sea necesario

    // // Datos del Cliente para la cabecera
    // const nombreCliente = 'Cliente: hola'
    // const codigoCliente = 'Código: hola'
    // const domicilioCliente = 'Dirección: hola'
    // const telefonoCliente = 'Telefono: hola'

    // // Coordenadas para la disposición de los datos del cliente
    // const xLeft = 10; // Coordenada X para el lado izquierdo
    // const xRight = doc.internal.pageSize.width / 2; // Coordenada X para el lado derecho
    // const yTop = lineaY + 5; // Coordenada Y para la parte superior

    // // Establecer la fuente y el tamaño de fuente para los datos del cliente
    doc.setFontSize(12);

    // // Nombre del cliente, Código y Teléfono en la misma línea
    // doc.text(nombreCliente, xLeft, yTop);
    // doc.text(codigoCliente, xRight, yTop);
    // doc.text(telefonoCliente, xRight + 30, yTop); // Ajusta la posición según sea necesario
    // // Dirección debajo de los datos anteriores
    // doc.text(domicilioCliente, xLeft, yTop + 6); // Ajusta la posición según sea necesario

    // // Dibujar una línea horizontal para los datos del cliente
    // doc.setDrawColor(0, 0, 0); // Color de la línea (negro)
    // doc.setLineWidth(lineHeight);
    // doc.line(10, lineaYCliente + 10, 10 + lineWidth, lineaYCliente + 10); // Dibuja la línea horizontal

    // Convierte las fechas al formato "DD/MM/YYYY"
    function formatDate(date) {
        const parts = date.split('-'); // Divide la fecha en año, mes y día
        const formattedDate = `${parts[2]}/${parts[1]}/${parts[0]}`; // Formatea la fecha como "DD/MM/YYYY"
        return formattedDate;
    }

    // Coloca el período de consulta debajo de la línea de datos del cliente
    const espacioEntreLineaClienteYFecha = 4; // Ajusta el espacio necesario
    doc.text('PERIODO DE CONSULTA:', 10, lineaYCliente + espacioEntreLineaClienteYFecha);
    //   doc.text(`Desde Fecha: ${formatDate(desdeFecha)}`, 65, lineaYCliente + espacioEntreLineaClienteYFecha);
    //   doc.text(`Hasta Fecha: ${formatDate(hastaFecha)}`, 115, lineaYCliente + espacioEntreLineaClienteYFecha);
    doc.text(`Desde Fecha: ${formatDate(fechaDesde)}`, 65, lineaYCliente + espacioEntreLineaClienteYFecha);
    doc.text(`Hasta Fecha: ${formatDate(fechaHasta)}`, 115, lineaYCliente + espacioEntreLineaClienteYFecha);


    // Trazar una línea horizontal después de las fechas del período de consulta
    const lineaYDespuesFechaConsulta = lineaYCliente + espacioEntreLineaClienteYFecha + 3; // Ajusta la posición vertical según sea necesario
    const lineWidthDespuesFechaConsulta = doc.internal.pageSize.width - 20; // Ajusta la longitud de la línea según sea necesario
    doc.setDrawColor(0, 0, 0); // Color de la línea (negro)
    doc.setLineWidth(0.5); // Ajusta el grosor de la línea según sea necesario
    doc.line(10, lineaYDespuesFechaConsulta, 10 + lineWidthDespuesFechaConsulta, lineaYDespuesFechaConsulta); // Dibuja la línea horizontal


    doc.setFont('helvetica'); // Restaurar la fuente original (sin negrita)

    const columnas = ['Fecha', 'N°Comprobante', 'Nombre', 'Entregado', 'Firmado', 'Imagen', 'Nota'];

    const registros = remitosPdf?.map((dato) => {
        const detalles = dato?.Movstock?.map((detalle) => ({
            data: [
                '', // Espacio vacío para mantener la alineación
                detalle.Codarticulo ? detalle.Codarticulo : '-',
                detalle.Descarticulo ? detalle.Descarticulo : '-',
                detalle.Cantidad ? detalle.Cantidad : '-',
                '', // Espacio vacío para mantener la alineación
                '', // Espacio vacío para mantener la alineación
                '', // Espacio vacío para mantener la alineación

            ],
            isRemito: false  // Indicar que esta fila es un detalle
        }));

        const remito = {
            data: [
                dato?.Fecha,
                dato?.Talonario + '-' + dato?.Nrocomprobante,
                dato?.Nombre,
                dato?.Entregas?.[0]?.Recibido == 'SI' ? 'SI' : 'NO',
                dato?.Entregas?.[0]?.Firma?.length > 0 ? 'SI' : 'NO',
                dato?.Entregas?.[0]?.Imagen?.length > 0 ? 'SI' : 'NO',
                dato?.Entregas?.[0]?.Nota,
            ],
            isRemito: true // Indicar que esta fila es un remito
        };

        return [remito, ...detalles];
    }).flat(); // Aplanar el array para que sea una lista de filas

    let firstPage = true;

    doc.autoTable({
        startY: 45, // Posición inicial en la página
        head: [columnas],
        body: registros.map((row) => row.data),
        headStyles: { halign: 'center', fontSize: 8, textColor: [0, 0, 0], fillColor: [173, 216, 230] },
        columnStyles: {
            0: { halign: 'center', fontSize: 7 },
            1: { halign: 'center', fontSize: 7 },
            2: { halign: 'center', fontSize: 6 },
            3: { halign: 'center', fontSize: 7 },
            4: { halign: 'center', fontSize: 7 },
            5: { halign: 'center', fontSize: 7 },
            6: { halign: 'center', fontSize: 6 },
        },
        tableWidth: 'auto', // Ajustar el ancho automáticamente dentro de los márgenes
        margin: { top: firstPage ? 10 : 56, right: 10, bottom: 20, left: 10 }, // Márgenes
        tableLineWidth: 0.2, // Ancho del borde de la tabla
        tableLineColor: [0, 0, 0], // Color del borde de la tabla (en este caso, negro)

        didParseCell: (data) => {
            const row = registros[data.row.index];
            if (row.isRemito) { // Estilo para el remito principal
                data.cell.styles.fontStyle = 'bold';
                data.cell.styles.textColor = [0, 0, 0]; // Color negro
            } else {

                // Establecer alineación a la derecha para la columna de Cantidad
                if (data.column.index === 1) { // Considerando que Descripcion es la quinta columna (índice 2)
                    data.cell.styles.halign = 'center';
                    data.cell.styles.textColor = 'blue';
                    data.cell.styles.fontStyle = 'bold';
                }
                if (data.column.index === 2) { // Considerando que Descripcion es la quinta columna (índice 2)
                    data.cell.styles.halign = 'left';
                    data.cell.styles.textColor = 'blue';
                    data.cell.styles.fontStyle = 'bold';
                }
                if (data.column.index === 3) { // Considerando que Cantidad es la quinta columna (índice 3)
                    data.cell.styles.halign = 'right';
                    data.cell.styles.textColor = 'blue';
                    data.cell.styles.fontStyle = 'bold';
                }

                data.cell.styles.fontSize = 6;
            }
        },

        didDrawPage: (data) => {
            // Agregar número de página
            doc.setFont('helvetica');
            const pageNumber = data.pageNumber;
            doc.text('Página N°: ' + pageNumber, 100, doc.internal.pageSize.height - 10);

            // Resetear la variable firstPage después de la primera página
            firstPage = false;
        }
    });



    doc.setFont('helvetica'); // Restaurar la fuente original (sin negrita)


    // Crear un Blob del PDF y abrirlo en una nueva pestaña
    const pdfBlob = doc.output('blob');
    const pdfUrl = URL.createObjectURL(pdfBlob);

    window.open(pdfUrl, '_blank');


}
