export default function ModalConfirmarEntrega({ handleRegistrarEntrega, handleCloseConfirmModal }) {
    return (
        <div className="flex justify-center items-center fixed inset-0 z-50 bg-opacity-80 bg-gray-800">
            <div className="bg-white text-black p-8">
                <h2 className="text-xl text-center font-semibold mb-4">¿CONFIRMAR ENTREGA?</h2>
                <div className="flex space-x-4">
                    <button
                        onClick={() => {
                            handleRegistrarEntrega(); // Ejecuta el registro
                            handleCloseConfirmModal(); // Cierra el modal
                        }}
                        type="button"
                        className="flex justify-center items-center px-6 text-xl py-2 bg-green-500 text-white  hover:bg-green-600 font-semibold"
                    >
                        CONFIRMAR
                    </button>
                    <button
                        onClick={handleCloseConfirmModal} // Sin paréntesis
                        type="button"
                        className="flex justify-center items-center px-6 text-xl py-2 bg-red-500 text-white  hover:bg-red-600 font-semibold"
                    >
                        CANCELAR
                    </button>
                </div>
            </div>
        </div>
    );
}
