import axios from 'axios'
import { useState } from "react"
import { useNavigate } from 'react-router-dom';
import MensajeTokenAdministrador from '../MensajeTokenAdministrador/MensajeTokenAdministrador';
import FirmaDigitalAdministrador from '../FirmaDigitalAdministrador/FirmaDigitalAdministrador';
import ToastErrorImagenOFirmaAdministrador from '../ToastAdministrador/ToastErrorImagenOFirmaAdministrador';
import ModalConfirmarEntregaAdministrador from './ModalConfirmarEntregaAdministrador';
import camara from '../../../images/camara.png'



export default function ModalRegistrarEntregaAdministrador({ selectedRemito, handleCloseModalRegistrarEntrega, setAvisoEntregaCorrecta }) {
    const navigate = useNavigate();

    const [mensajeError, setMensajeError] = useState('');
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [toggleMenu, setToggleMenu] = useState(false);
    // estados del formulario
    const [imagen, setImagen] = useState(null)
    const [nombreImagen, setNombreImagen] = useState('')
    const [firma, setFirma] = useState('')
    const [nota, setNota] = useState('')
    const recibido = 'SI'
    const claveRemito = selectedRemito?.Clave
    const codigoVendedor = selectedRemito?.Codvendedor

    const handleInputImagenChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setNombreImagen(file.name)
            const reader = new FileReader();
            reader.onload = () => {
                setImagen(reader.result); // Guarda la imagen en Base64 en el estado
            };
            reader.readAsDataURL(file); // Convierte la imagen a Base64
        } else {
            setNombreImagen('')
        }
    };

    const handleInputNotaChange = (e) => {
        const notaIngresada = e.target.value
        setNota(notaIngresada)
    }

    // const handleCloseToastErrorImagenOFirma = () => setErrorImagenOFirma(false)
    const handleCloseToastErrorImagenOFirma = () => setMensajeError('')

    const handleOpenConfirmModal = () => setShowConfirmModal(true);
    const handleCloseConfirmModal = () => setShowConfirmModal(false);


    const handleRegistrarEntrega = async () => {

        const token = localStorage.getItem('auth_usuario');
        const NombreAdministrador = localStorage.getItem('NombreAdministrador')
        if (!token ||  !NombreAdministrador) {
            localStorage.removeItem('auth_usuario')
            localStorage.removeItem('NombreAdministrador')
            navigate('/loginAdministrador');
            return;
        }

        // Validar que haya una imagen o una firma
        if (!imagen && !firma) {
            setMensajeError('INGRESE FOTO O FIRMA');
            return;
        }
        setMensajeError('');

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/entregas/administrador/registrarentrega`,
                {
                    imagen,
                    firma,
                    nota,
                    recibido,
                    claveRemito,
                    codigoVendedor,
                    NombreAdministrador
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.status === 201) {
                setAvisoEntregaCorrecta(true)
                setMensajeError('')
                setImagen('')
                setNombreImagen('')
                setNota('')
                setFirma('')
                handleCloseModalRegistrarEntrega()
            }

        } catch (error) {
            console.error('Error en la solicitud:', error);
            if (error.response) {
                const { status, data } = error.response;

                if (status === 400) {
                    setMensajeError(data.message || 'Datos inválidos.');
                } else if (status === 401) {
                    // Error de token inválido o expirado
                    setToggleMenu(true);
                    setMensajeError(data.message);
                    localStorage.removeItem('auth_usuario');
                    localStorage.removeItem('NombreAdministrador');
                } else {
                    setMensajeError('Ocurrió un error inesperado al registrar la entrega.');
                }
            } else {
                setMensajeError('Error de red o conexión.');
            }
        }
    }

    return (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg p-4 lg:p-5 w-11/12 sm:w-4/5 lg:w-[30%] max-w-full shadow-lg relative max-h-[80vh] overflow-y-auto">
                <form onSubmit={handleRegistrarEntrega} className="space-y-2 ">
                    <div className="grid grid-cols-1 gap-3">
                        <label className='-mb-4  font-semibold'>Foto</label>
                        <div className="relative">
                            <input
                                type="file"
                                accept="image/*"
                                capture="environment"
                                id="fileInput"
                                className="hidden" // Escondemos el input original
                                onChange={handleInputImagenChange}
                            />
                            <label
                                htmlFor="fileInput" // Asocia el label al input por su id
                                className="flex items-center justify-center w-full p-1.5 lg:p-1 bg-blue-500 text-white rounded-md cursor-pointer hover:bg-blue-600"
                            >
                                <img src={camara} alt="camara" className='h-10 w-10' />
                            </label>
                        </div>
                        {nombreImagen && (<p>{nombreImagen}</p>)}

                        {/* FIRMA DIGITAL */}
                        <FirmaDigitalAdministrador setFirma={setFirma} />


                        <label className='-mb-4 -mt-2 font-semibold'>Nota</label>
                        <div className="rounded border border-black">
                            <input
                                type="text"
                                value={nota}
                                placeholder="Ingrese nota si es necesario..."
                                className="w-full p-2 lg:p-1 focus:outline-none text-black"
                                onChange={handleInputNotaChange}
                            />
                        </div>
                    </div>

                    {/* Submit Button */}
                    <div className="flex justify-center">
                        <button
                            onClick={handleOpenConfirmModal}
                            type="button"
                            className="p-1.5 lg:p-1.5  w-full text-xl lg:text-base  rounded-sm mt-3 font-semibold bg-green-500 hover:bg-green-600 border border-black text-white"
                        >
                            GUARDAR
                        </button>
                    </div>
                </form>
                <div className="flex justify-center">
                    <button
                        onClick={handleCloseModalRegistrarEntrega}
                        className="p-1.5 lg:p-1.5  w-full text-xl lg:text-base rounded-sm mt-4 font-semibold bg-red-600 hover:bg-red-700 border border-black text-white"
                    >
                        CERRAR
                    </button>
                </div>
            </div>

            {showConfirmModal && (<ModalConfirmarEntregaAdministrador handleRegistrarEntrega={handleRegistrarEntrega} handleCloseConfirmModal={handleCloseConfirmModal} />)}
            {/* {errorImagenOFirma && (<ToastErrorImagenOFirma handleCloseToastErrorImagenOFirma={handleCloseToastErrorImagenOFirma} />)} */}
            {mensajeError && !toggleMenu && (<ToastErrorImagenOFirmaAdministrador mensajeError={mensajeError} handleCloseToastErrorImagenOFirma={handleCloseToastErrorImagenOFirma} />)}

            {toggleMenu && (<MensajeTokenAdministrador mensajeError={mensajeError} />)}
        </div>
    )
}
